import React from 'react'
import './Footer.css';

export default function Footer(){
    return(
        <footer className="footer-container">
            <a href="https://github.com/ArmaanKara/website" color="white">
                This website was desgined and built by Armaan Kara
            </a>
        </footer>
    )
}
