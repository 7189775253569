import React from 'react';
import './LoadingScreen.css'


const LoadingScreen = () => {
    return(
        <div className="container">
            <div className="typewriter">
                <h1>Hello, welcome to my website...</h1>
    
            </div>
        </div>
    )

}

export default LoadingScreen